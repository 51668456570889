import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {addToCart, getAllCategories, getAllProducts} from '../actions'
import {getVisibleProducts} from '../reducers/products'
import ProductItem from '../pages/shop/cards/ProductItem'
import ProductsList from '../pages/shop/cards/ProductList'
import {getVisibleCategories} from "../reducers/categories";

const ProductsContainer = ({ products, categories, addToCart, getAllProducts, getAllCategories }) => {

    useEffect(() => {
        getAllProducts();
        getAllCategories()
    }, [getAllProducts, getAllCategories]);

    return(
        <ProductsList categories={categories}>
            {products.map(product =>
                <ProductItem
                    key={product.productId}
                    product={product}
                    onAddToCartClicked={() => {
                        addToCart(product.productId)
                    }} />
            )}
        </ProductsList>
    )
}

ProductsContainer.propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape({
        productId: PropTypes.number.isRequired,
        productName: PropTypes.string.isRequired,
        brandName: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        inventory: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
        specialPrice: PropTypes.number.isRequired,
        discount: PropTypes.number.isRequired,
        category: PropTypes.shape({
            categoryId: PropTypes.number.isRequired,
            categoryName: PropTypes.string.isRequired,
        }).isRequired,
    })).isRequired,
    categories: PropTypes.shape({
        categoryId: PropTypes.number.isRequired,
        categoryName: PropTypes.string.isRequired,
    }).isRequired,
    addToCart: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    products: getVisibleProducts(state.products),
    categories: getVisibleCategories(state.categories)
})

// const mapStateToProps = state => {
//     debugger
//     const products =  getVisibleProducts(state.products)
//     return {
//
//         products: products
//         // products: state.products
//
//     };
// };

export default connect(
    mapStateToProps,
    { addToCart, getAllProducts, getAllCategories }
)(ProductsContainer)
