import {combineReducers} from 'redux'
import { RECEIVE_PRODUCTS} from '../constants/ActionTypes'

const products = (state, action) => {
    switch (action.type) {
        // case ADD_TO_CART:
        //     return {
        //         ...state,
        //         inventory: state.inventory - 1
        //     }
        default:
            return state
    }
}

const byId = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_PRODUCTS:
            // console.log("akc")
            // console.log(action.products.reduce())
            return {
                ...state,
                ...action.products.content.reduce((obj, product) => {
                    obj[product.productId] = product
                    return obj
                }, {})
            }
        default:
            const { productId } = action
            if (productId) {
                return {
                    ...state,
                    [productId]: products(state[productId], action)
                }
            }
            return state
    }
}

const visibleIds = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_PRODUCTS:
            return action.products.content.map(product => product.productId)
        default:
            return state
    }
}

export default combineReducers({
    byId,
    visibleIds
})

export const getProduct = (state, productId) => {
    return state.byId[productId];
};


export const getVisibleProducts = state =>
    state.visibleIds.map(id => getProduct(state, id))
