import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../product/ProductPage.css';
import './ProductList.css';
import "./FilterSortComponent.css";
import "../../../fonts/fonts.css";
import { connect } from "react-redux";
import { getVisibleCategories } from "../../../reducers/categories";

const ProductsList = ({ children, categories }) => {
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [priceFrom, setPriceFrom] = useState('');
    const [priceTo, setPriceTo] = useState('');
    const [sortOrder, setSortOrder] = useState('default');
    const [sortDisplayText, setSortDisplayText] = useState('Sortiranje');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const maxOptionWidth = '15rem'; // Fixed width based on longest option text


    useEffect(() => {
        switch (sortOrder) {
            case 'price-asc':
                setSortDisplayText('Sortiranje (Cijena: niža prema višoj)');
                break;
            case 'price-desc':
                setSortDisplayText('Sortiranje (Cijena: viša prema nižoj)');
                break;
            default:
                setSortDisplayText('Sortiranje');
        }
    }, [sortOrder]);

    const handleSearchChange = (e) => setSearchQuery(e.target.value);
    const handleCategoryToggle = (categoryId) => {
        if (selectedCategories.includes(categoryId)) {
            setSelectedCategories(selectedCategories.filter(catId => catId !== categoryId));
        } else {
            setSelectedCategories([...selectedCategories, categoryId]);
        }
    };
    const handlePriceFromChange = (e) => setPriceFrom(e.target.value);
    const handlePriceToChange = (e) => setPriceTo(e.target.value);
    const handleSortOrderChange = (value) => {
        setSortOrder(value);
        setDropdownVisible(false);
    };

    const countAppliedFilters = () => {
        let count = 0;
        if (searchQuery) count++;
        if (selectedCategories.length > 0) count++;
        if (priceFrom || priceTo) count++;
        return count;
    };

    const appliedFiltersCount = countAppliedFilters();

    const applyFilters = () => {
        setFiltersVisible(false);
    };

    const cancelFilters = () => {
        setSearchQuery('');
        setSelectedCategories([]);
        setPriceFrom('');
        setPriceTo('');
        setFiltersVisible(false);
    };

    const filterProducts = (product) => {
        return (
            (selectedCategories.length === 0 || selectedCategories.includes(product.props.product.category.categoryId)) &&
            (priceFrom === '' || (product.props.product.price >= priceFrom)) &&
            (priceTo === '' || (product.props.product.price <= priceTo)) &&
            (searchQuery === '' || product.props.product.productName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                product.props.product.brandName.toLowerCase().includes(searchQuery.toLowerCase()))
        );
    };

    const sortProducts = (a, b) => {
        if (sortOrder === 'price-asc') {
            return a.props.product.price - b.props.product.price;
        } else if (sortOrder === 'price-desc') {
            return b.props.product.price - a.props.product.price;
        } else {
            return 0;
        }
    };

    const filteredAndSortedProducts = React.Children.toArray(children)
        .filter(filterProducts)
        .sort(sortProducts);

    return (
        <div style={{ paddingTop: "10rem", backgroundColor: "white" }}>
            <div className="filter-sort-wrapper">
                <div className="filter-sort-container">
                    <button className="filter-menu-btn" onClick={() => setFiltersVisible(!filtersVisible)}>
                        Filter {appliedFiltersCount > 0 ? `(${appliedFiltersCount})` : ''}
                    </button>
                    {appliedFiltersCount > 0 &&
                    <button
                        className="close-button-filter"
                        type="button"
                        onClick={cancelFilters}
                    >×</button>}
                    <div className="sort-container-wrapper" style={{ minWidth: maxOptionWidth }}>
                        <button className="sort-button" onClick={() => setDropdownVisible(!dropdownVisible)}>
                            {sortDisplayText}
                        </button>
                        {dropdownVisible && (
                            <div className="sort-dropdown">
                                <div onClick={() => handleSortOrderChange('default')}>Default</div>
                                <div onClick={() => handleSortOrderChange('price-asc')}>Cijena: niža prema višoj</div>
                                <div onClick={() => handleSortOrderChange('price-desc')}>Cijena: viša prema nižoj</div>
                            </div>
                        )}
                    </div>
                    {filtersVisible && (
                        <>
                            <div className="overlay" onClick={() => setFiltersVisible(false)}></div>
                            <div className="filter-modal">
                                <div className="modal-header" id="modal-header" style={{ borderBottom: "1px solid lightgray" }}>
                                    <div className="modal-headline">Filteri</div>
                                    <button
                                        className="close-button"
                                        type="button"
                                        onClick={() => setFiltersVisible(false)}
                                    >×</button>
                                </div>
                                <div className="filter-options">
                                    <div>
                                        <div className="category-headline">
                                            Kategorije
                                        </div>
                                        <div className="categories">
                                            {categories.map(category => (
                                                <button
                                                    key={category.categoryId}
                                                    className={`category-button ${selectedCategories.includes(category.categoryId) && "active"}`}
                                                    onClick={() => handleCategoryToggle(category.categoryId)}
                                                >
                                                    {category.categoryName}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="category-headline">
                                            Cijena
                                        </div>
                                        <div className="price-range">
                                            <input
                                                type="number"
                                                placeholder="Od"
                                                className="price-input"
                                                value={priceFrom}
                                                onChange={handlePriceFromChange}
                                            />
                                            <input
                                                type="number"
                                                placeholder="Do"
                                                className="price-input"
                                                value={priceTo}
                                                onChange={handlePriceToChange}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="category-headline">
                                            Tražilica
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Pretraži..."
                                            className="search-input"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                    <div className="filter-buttons">
                                        <button className="apply-btn" id="apply-cancel-btn" onClick={applyFilters}>Primjeni {appliedFiltersCount > 0 ? `(${appliedFiltersCount})` : ''}</button>
                                        <button className="cancel-btn" id="apply-cancel-btn" onClick={cancelFilters}>Otkaži</button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="cards padding background-white">
                <div className='cards__container background-white'>
                    <div className='cards__wrapper background-white'>
                        <div className='grid-container background-white'>
                            {filteredAndSortedProducts}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ProductsList.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
        categoryId: PropTypes.number.isRequired,
        categoryName: PropTypes.string.isRequired,
    })).isRequired,
};

const mapStateToProps = state => ({
    categories: getVisibleCategories(state.categories)
});

export default connect(
    mapStateToProps,
)(ProductsList);
