import React, {useEffect, useState} from 'react';
import './HeroSection.css';
import {useNavigate} from 'react-router-dom';
import shop_cvijet from '../images/cvijet cropped.jpg'
import creme_face from '../images/salon_lice cropped.jpg'
import facial from '../images/natpis2 novi copy cropped.png'
import facial_mala from '../images/naslovna manja.png'
import "../fonts/fonts.css"


function HeroSection() {
    // const loggedIn = localStorage.getItem("loggedIn") === "true"
    let navigate = useNavigate();
    const [aspectRatio, setAspectRatio] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            const ratio = window.innerHeight / window.innerWidth;
            setAspectRatio(ratio);
        };

        handleResize(); // Initial calculation

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // console.log(aspectRatio)



  return (
        <div className="hero-section">
            {/*<Navbar/>*/}
            <div className="image-container2">
                {/*<img src={imageSrc} className="" alt="photo"/>*/}
                <div id="image-container">
                    {aspectRatio >= 0.9 ? (
                        <img src={facial_mala} alt="Vertical" />
                    ) : (
                        <img src={facial} alt="Horizontal" />
                    )}
                </div>
            </div>

            <div className="content">
            <div className="webshop-treatment-container">
                <div className="treatment-container">
                    <img src={creme_face} className="webshop-photo" alt=""/>
                    <button id="hero-btn" type="button" className="font-pro btn btn-primary margin-top centered-button"
                            onClick={() => navigate("/tretmani")}>
                        Salon
                    </button>
                </div>
                <div className="webshop-container">
                    <img src={shop_cvijet}  className="webshop-photo" alt=""/>
                    <button id="hero-btn" type="button" className="font-tiskani-mali btn btn-primary margin-top centered-button"
                            onClick={() => navigate("/shop")}>
                        Webshop
                    </button>
                </div>
            </div>
        </div>
        </div>
  );
}

export default HeroSection;
