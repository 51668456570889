
import microneedling1 from '../../images/microneedling1.jpg'
import './Microneedling.css'

const Microneedling = () => {
    return(
    <div className="container">
        <h1 className="font-pisani" >Microneedling</h1>

        <div className="section">
            <h2>Što je Microneedling?</h2>

            <div className="container">
                <div className="content">
                    <img src={microneedling1} alt="Microneedling Procedure"/>
                </div>
                <div className="content font-tiskani">
                    <p>Microneedling is a minimally invasive cosmetic procedure that's used to treat skin concerns via
                        collagen production. Also known as collagen induction therapy, this treatment may help those
                        looking to reduce the appearance of acne scars and stretch marks. During a microneedling
                        procedure, very fine needles are used to create hundreds of tiny, invisible puncture wounds in
                        the top layer of skin. This process stimulates the body's natural wound healing process,
                        resulting in increased collagen and elastin production, which helps to rejuvenate the skin.</p>
                </div>
            </div>
        </div>

        <div className="section">
            <h2>Benefits of Microneedling</h2>
            <img src="microneedling_2.jpg" alt="Microneedling Before and After"/>
                <p>Microneedling can improve skin texture, reduce the appearance of scars, including those caused by acne,
                    and stimulate collagen production, which can lead to firmer, more youthful-looking skin. It may also
                    help with reducing the appearance of fine lines and wrinkles and improving the effectiveness of topical
                    skincare products.</p>
        </div>

        <div className="section">
            <h2>Risks of Microneedling</h2>
            <img src="microneedling_3.jpg" alt="Microneedling Risks"/>
                <p>Although microneedling is considered safe for most people, it may not be suitable for individuals with
                    certain skin conditions or medical histories. Potential risks include skin irritation, redness,
                    bruising, and infection. It's important to consult with a qualified healthcare professional before
                    undergoing microneedling to determine if it's appropriate for you.</p>
        </div>

        <div className="section">
            <h2>Care After Microneedling</h2>
            <img src="microneedling_4.jpg" alt="Microneedling Post-care"/>
                <p>After microneedling, it's essential to follow post-care instructions provided by your healthcare provider
                    to optimize results and minimize the risk of complications. This may include avoiding sun exposure,
                    using gentle skincare products, avoiding picking or scratching the treated area, and staying hydrated.
                    Your provider may also recommend specific skincare products or treatments to enhance the results of
                    microneedling.</p>
        </div>
    </div>
        )
};

export default Microneedling;
