import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {useParams, Link, useNavigate} from "react-router-dom";
import {connect, useSelector} from 'react-redux';
import { getOrder } from "../../reducers/order";
import {
     cancelOrderAdmin, deleteOrder,
    getAllOrders,
    orderNotPaid,
    orderNotSent,
    orderPaid,
    orderSent,
} from "../../actions";
import "./OrderPage.css";
import "../../fonts/fonts.css"

const withRouter = (Component) => (props) => {
    const params = useParams()
    return <Component {...props} params={params} />
}

const OrderPage = ({ order, orderPaid, orderSent, orderNotPaid, orderNotSent, deleteOrder, cancelOrderAdmin }) => {
    const { orderId } = useParams(); // This will get the orderId from the route params
    console.log(orderId)

    const reduxState = useSelector((state) => state); // Get the entire Redux state
    const navigate = useNavigate();


    useEffect(() => {
        // console.log('Redux State:', reduxState); // Log the entire Redux state
    }, [reduxState]);

    if (!order) {
        return <div>Order not found</div>; // Render error message if product is not found
    }
    // useEffect(() => {
    //     // Dispatch the getAllProducts action when the component mounts
    //     getOrder(orderId);
    // }, [getOrder(orderId)]);
    //
    // if (!order) {
    //     return <div>Loading...</div>; // Render loading indicator if products or total are not available
    // }

    // useEffect(() => {
    //     // Fetch the updated order data after marking it as paid or shipped
    //     getOrder(orderId);
    // }, [orderId, getOrder]);

    // const markPaid = () => {
    //     orderPaid(order.orderId)
    // }

    const markPaid = async () => {
        const userConfirmation = window.confirm("Jeste li sigurni da želite označiti narudžbu kao plaćenu?");
        // If user confirms, proceed with canceling the order
        if (userConfirmation) {
            // Call the orderShipped action to mark the order as shipped
            await orderPaid(order.orderId);

            // Fetch all orders again from the server
            await getAllOrders(); // Dispatch getAllOrders action after successful login
            navigate("/narudzbe")
        }
        // window.location.reload();
    };

    const markSent = () => {
        const userConfirmation = window.confirm("Jeste li sigurni da želite označiti narudžbu kao poslanu?");
        // If user confirms, proceed with canceling the order
        if (userConfirmation) {
            orderSent(order.orderId)
            navigate("/narudzbe")
        }
    }

    const markNotSent = () => {
        const userConfirmation = window.confirm("Jeste li sigurni da želite označiti narudžbu kao neposlanu?");
        // If user confirms, proceed with canceling the order
        if (userConfirmation) {
            orderNotSent(order.orderId)
            navigate("/narudzbe")
        }
    }

    const markNotPaid = () => {
        const userConfirmation = window.confirm("Jeste li sigurni da želite označiti narudžbu kao neplaćenu?");
        // If user confirms, proceed with canceling the order
        if (userConfirmation) {
            orderNotPaid(order.orderId)
            navigate("/narudzbe")
        }
    }


    const onCancelOrder = () => {
        const userConfirmation = window.confirm("Jeste li sigurni da želite otkazati narudžbu?");
        // If user confirms, proceed with canceling the order
        if (userConfirmation) {
            cancelOrderAdmin(order.orderId)
            navigate("/narudzbe")
        }
    }

    return (
        <div className="order-container font-tiskani-order-page">
            <div className="order">
                <div className="padding-order-page-text">
                <h2 className="font-tiskani">Detalji narudžbe</h2>
                </div>
                {order && (
                    <React.Fragment>
                        <div style={{padding: "0 1rem"}} className="font-tiskani-order-page-text padding-order-page-text">
                        <div className="section">
                            <p className=""><strong>ID narudžbe:</strong> {order.orderId}</p>
                            <p className=""><strong>Datum narudžbe:</strong> {order.orderDate}</p>
                            <p className=""><strong>Status:</strong> {order.statusLabel}</p>
                            <p className=""><strong>Iznos:</strong> ${order.totalAmount}</p>

                            {order.orderStatus === "CONFIRMED" &&
                                <button id="btn-paid" type="button"  onClick={markPaid}>Označi kao plaćeno</button>
                            }
                            {order.orderStatus === "PAID" &&
                                <button id="btn-paid" type="button"  onClick={markSent}>Označi kao poslano</button>
                            }
                            {order.orderStatus === "PAID" &&
                                <button id="btn-paid" type="button"  onClick={markNotPaid}>Označi kao nije plaćeno</button>
                            }
                            {order.orderStatus === "SENT" &&
                                <button id="btn-paid" type="button"  onClick={markNotSent}>Označi kao nije poslano</button>
                            }
                            {order.orderStatus === "CONFIRMED" &&
                                <button id="btn-paid" type="button"  onClick={onCancelOrder}>Otkaži narudžbu</button>
                            }
                            {/*{(order.orderStatus === "CANCELED_BY_USER" || order.orderStatus === "CANCELED_BY_ADMIN") &&*/}
                            {/*    <button id="btn-paid" type="button"  onClick={handleDeleteOrder}>Obriši narudžbu</button>*/}
                            {/*}*/}
                            {/*{(order.orderStatus === "CANCELED_BY_USER" || order.orderStatus === "CANCELED_BY_ADMIN") &&*/}
                            {/*    <button id="btn-paid" type="button"  onClick={handleDeleteOrder}>Obriši narudžbu</button>*/}
                            {/*}*/}
                        </div>
                        <div className="section">
                            <h3 className="">Shipping Address</h3>
                            <p className=""><strong>Ime i prezime:</strong> {order.firstName} {order.lastName}</p>
                            <p className=""><strong>Broj mobitela:</strong> {order.phoneNumber}</p>
                            <p className=""><strong>Email adresa:</strong> {order.email}</p>
                            <p className=""><strong>Adresa:</strong> {order.address.street}, {order.address.city}, {order.address.country} - {order.address.postcode}</p>
                            <p className=""><strong>Dostava:</strong> {order.shippingMethod}</p>
                            <p className=""><strong>Cijena dostave:</strong> €{order.shippingMethodPrice}</p>
                        </div>
                        </div>
                        <div className="section">
                            <h3 className="font-tiskani-order-page">Order Items</h3>
                            <table className="order-items-table">
                                <thead>
                                <tr>
                                    <th>Artikl</th>
                                    <th>Brend</th>
                                    <th>Količina</th>
                                    <th>Cijena</th>
                                    <th>Popust</th>
                                    <th>Slika</th>
                                    <th>Inventar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {order.orderItems.map(item => (
                                    <tr key={item.orderItemId}>
                                        <td>
                                            <Link to={`/shop/${item.product.productId}`}>{item.product.productName}</Link>
                                        </td>
                                        <td>{item.product.brandName}</td>
                                        <td>{item.quantity}</td>
                                        <td>€{item.orderedProductPrice*item.quantity}</td>
                                        <td>{item.discount}%</td>
                                        <td><img src={"/images/products/"+item.product.image.split(",")[0]} alt={item.product.productName} style={{ width: '50px', height: '50px' }} /></td>
                                        <td>{item.product.inventory}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

OrderPage.propTypes = {
    order: PropTypes.object,
    getOrder: PropTypes.func.isRequired,
    orderPaid: PropTypes.func.isRequired,
    orderSent: PropTypes.func.isRequired,
    orderNotPaid: PropTypes.func.isRequired,
    orderNotSent: PropTypes.func.isRequired,
    getAllOrders: PropTypes.func.isRequired,
    cancelOrderAdmin: PropTypes.func.isRequired,
    deleteOrder: PropTypes.func.isRequired,

};

const mapStateToProps = (state, ownProps) => ({
    order: getOrder(
        state.orders,
        parseInt(ownProps.params.orderId, 10)
    ),
})

const mapDispatchToProps = {
    getOrder,
    orderPaid,
    orderSent,
    orderNotPaid,
    orderNotSent,
    getAllOrders,
    cancelOrderAdmin,
    deleteOrder
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        OrderPage
    )
)
