import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Product from './Product'
import { useNavigate } from 'react-router-dom';
import "./Cart.css"
import "../../../fonts/fonts.css"

import './Cart.css'
import {
    MDBCard,
    MDBCardBody, MDBCol,
    MDBContainer,
    MDBInput, MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead, MDBTextArea,
} from "mdb-react-ui-kit";
import { useSelector} from "react-redux";

const Cart  = ({ products, total, totalWithoutDiscount, onCheckout, onDecrement, onIncrement, getAllProducts, onDeleteAll }) => {

    const navigate = useNavigate();
    const cartProducts = useSelector(state => state.cart.quantityById);
    const stateProducts = useSelector(state => state.products.byId);


    const hasProducts = products.length > 0
    const nodes = hasProducts ? (
        products.map(product =>
            <Product
                productName={product.productName}
                brandName={product.brandName}
                image={product.image.split(",")[0]}
                price={product.price}
                quantity={product.quantity}
                productId={product.productId}
                discount={product.discount}
                specialPrice={product.specialPrice}
                key={product.productId}
                onCheckout={onCheckout}
                onDecrement={onDecrement}
                onIncrement={onIncrement}
                onDeleteAll={onDeleteAll}
            />
        )
    ) : (
        <em>Košarica je prazna.</em>
    )

    const [selectedOption, setSelectedOption] = useState('Pickup u salonu (Klaićeva 18)');

    // State to track whether to display columns
    const [showColumns, setShowColumns] = useState(window.innerWidth >= 800);

    // useEffect hook to listen to window resize events and update the state accordingly
    useEffect(() => {
        const handleResize = () => {
            setShowColumns(window.innerWidth >= 800);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures this effect runs only once on mount



    // Define shipping options with associated prices
    const shippingOptions = [
        { option: 'Pickup u salonu (Klaićeva 18)', price: 0 },
        { option: 'Dostava unutar Zagreba', price: 0 },
        { option: 'Dostava izvan Zagreba', price: 10 },
    ];


    const getPrice = (option) => {
        // console.log(shippingOptions.find((opt) => opt.option === option)?.price)
        return shippingOptions.find((opt) => opt.option === option)?.price;
    }


    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const [formData, setFormData] = useState(() => {
        // Load form data from session storage if available
        const formDataFromStorage = sessionStorage.getItem('cartFormData');
        return formDataFromStorage ? JSON.parse(formDataFromStorage) : {
            firstName: '',
            lastName: '',
            companyName: '',
            street: '',
            city: '',
            postcode: '',
            email: '',
            phoneNumber: '',
            additionalInfo: '',
            shippingOption: '',
            shippingOptionPrice: '',
        };
    });

    const [imeError, setImeError] = useState('');
    const [prezimeError, setPrezimeError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');


    const [streetError, setStreetError] = useState('');
    const [cityError, setCityError] = useState('');
    const [postcodeError, setPostcodeError] = useState('');







const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^\d{5,15}$/;
    return phoneNumberRegex.test(phoneNumber);
};



    const handleChange = (e) => {
        const { name, value } = e.target;
        // console.log(e.target)
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setImeError('');
        setPrezimeError('');
        setEmailError('');
        setPhoneNumberError('');

        setStreetError('');
        setPostcodeError('');
        setCityError('');

        // setIsValidEmail(validateEmail(value));
        console.log(formData)
    };


    useEffect(() => {
        // Dispatch the getAllProducts action when the component mounts
        getAllProducts();
    }, [getAllProducts]);

    const handleSubmit2 = async (e) => {
        e.preventDefault();

        try {
            // Check inventory for each product in the cart
            const insufficientInventoryProducts = [];
            Object.keys(cartProducts).forEach(productId => {
                const product = stateProducts[productId];
                if (product && product.inventory < cartProducts[productId]) {
                    insufficientInventoryProducts.push(product.productName);
                }
            });

            if (insufficientInventoryProducts.length === 0) {
                // Proceed with cart submission logic
                handleSubmit(e)
                // dispatch(submitCart()); // Assuming you have an action to submit the cart
            } else {
                // Alert user about insufficient inventory
                alert(`Nedovoljna zaliha za sljedeće proizvode: ${insufficientInventoryProducts.join(', ')}. Molim Vas smanjite količinu u košarici.`);
            }

        } catch (error) {
            console.error('Error:', error);
            // Handle error
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        formData.shippingOption = selectedOption;
        formData.shippingOptionPrice = getPrice(selectedOption);

        if (selectedOption === 'Dostava unutar Zagreba') {
            formData.city = "Zagreb";
            formData.postcode = "10000";
        }

        if (selectedOption === 'Pickup u salonu (Klaićeva 18)') {
            formData.city = "Zagreb";
            formData.postcode = "10000";
            formData.street = "Klaićeva 18";
        }

        sessionStorage.setItem('cartFormData', JSON.stringify(formData));

        let err = false;

        if (!formData.firstName) {
            setImeError('Ime je obavezno polje.');
            err = true;
        }

        if (!formData.lastName) {
            setPrezimeError('Prezime je obavezno polje.');
            err = true;
        }

        if (!formData.email) {
            setEmailError('Email adresa je obavezno polje.');
            err = true;
        } else if (!validateEmail(formData.email)) {
            setEmailError('Molimo upišite ispravnu email adresu.');
            err = true;
        }

        if (!formData.phoneNumber) {
            setPhoneNumberError('Broj mobitela je obavezno polje.');
            err = true;
        } else if (!validatePhoneNumber(formData.phoneNumber)) {
            setPhoneNumberError('Molimo upišite ispravan broj mobitela.');
            err = true;
        }

        if (!formData.street) {
            setStreetError('Ulica i kućni broj je obavezno polje.');
            if (selectedOption!=="Pickup u salonu (Klaićeva 18)") {
                err = true;
            }
        }

        if (!formData.city) {
            setCityError('Grad je obavezno polje.');
            if (selectedOption==="Dostava izvan Zagreba") {
                err = true;
            }
        }

        if (!formData.postcode) {
            setPostcodeError('Poštanski broj je obavezno polje.');
            if (selectedOption==="Dostava izvan Zagreba") {
                err = true;
            }
        }

        if (err === true) {
            e.preventDefault();
            return;
        }

        console.log(products)

        const orderItems = products.map(product => ({
            productId: product.productId,
            quantity: product.quantity, // Set the quantity to 1 for each product
            discount: product.discount,
            orderedProductPrice: product.specialPrice
        }));

        console.log("orderitems", orderItems)

        const data = {
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            companyName: formData.companyName,
            phoneNumber: formData.phoneNumber,
            addressDTO: {
                street: formData.street,
                city: formData.city,
                country: 'Hrvatska', // Replace 'Country Name' with the actual country name
                postcode: formData.postcode
            },
            shippingMethod: formData.shippingOption,
            shippingMethodPrice: formData.shippingOptionPrice,
            totalAmountCart: total,
            orderItems: orderItems,
        }

        console.log("calling oncheckout", data)

        onCheckout(data)
            .then(async order => {
                // After successful checkout, navigate to the payment page with the required data
                const deliveryData = {
                    delivery: selectedOption,
                    deliveryPrice: getPrice(selectedOption),
                    order: order // Include the order data if needed in the payment page
                };

                // console.log("navugate")
                // Navigate to the payment page with the delivery data
                await getAllProducts()
                navigate('/payment', {state: deliveryData});


            })
            .catch(error => {
                // Handle errors
                console.error('Checkout error:', error);
            });

    };


    const formFields = <div className="mx-auto mt-5" style={{maxWidth: '900px'}}>
        <MDBRow>
            <MDBCol md="8" className="mb-4">
                <MDBCard className="mb-4">
                    <MDBCardBody>
                        <form id="myForm" onSubmit={handleSubmit}>
                            <MDBRow className="mb-4">
                                <MDBCol>
                                    <MDBInput
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        className="font-pro"
                                        placeholder='Ime*'
                                        type='text'
                                        // required
                                        // onInvalid={(e) => e.target.setCustomValidity('Ime je obavezno polje')} // Custom error message
                                    />
                                    {imeError && <span className="font-pro" style={{color: "red"}}>Ime je obavezno polje.</span>}
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        className="font-pro"
                                        placeholder='Prezime*'
                                        type='text'
                                        // required
                                        // onInvalid={(e) => e.target.setCustomValidity('Prezime je obavezno polje')} // Custom error message
                                    />
                                    {prezimeError && <span className="font-pro" style={{color: "red"}}>Prezime je obavezno polje.</span>}
                                </MDBCol>
                            </MDBRow>
                            <MDBInput
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleChange}
                                className="font-pro mb-4"
                                placeholder='Ime firme (nije obavezno)'
                                type='text'
                            />
                            <MDBRow style={{paddingBottom: "1.5rem"}}>
                            <MDBInput
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder='Email adresa*'
                                type='text'
                                className="font-pro"
                                // required
                                // onInvalid={(e) => e.target.setCustomValidity('Email adresa je obavezno polje')} // Custom error message
                            />
                            {emailError && <span className="font-pro" style={{color: "red"}}>{emailError}</span>}
                            </MDBRow>

                            <MDBRow style={{paddingBottom: "1.5rem"}}>
                            <MDBInput
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                placeholder='Broj mobitela*'
                                type='text'
                                className=" font-pro"
                                // required
                                // onInvalid={(e) => e.target.setCustomValidity('Broj mobitela je obavezno polje')} // Custom error message
                            />
                            {phoneNumberError && <span className="font-pro mb-4" style={{color: "red"}}>{phoneNumberError}</span>}
                            </MDBRow>

                            {selectedOption !== "Pickup u salonu (Klaićeva 18)" && <>
                            <MDBRow style={{paddingBottom: "1.5rem"}}>
                            <MDBInput
                                name="street"
                                value={formData.street}
                                onChange={handleChange}
                                className="font-pro"
                                placeholder='Ulica i kućni broj*'
                                type='text'
                                // required
                                // onInvalid={(e) => e.target.setCustomValidity('Ulica i kućni broj je obavezno polje')} // Custom error message

                            />
                                <span className="font-pro" style={{color: "red"}}>{streetError}</span>
                            </MDBRow>

                            <MDBRow style={{paddingBottom: "1.5rem"}}>
                            <MDBInput
                                name="city"
                                value={selectedOption==="Dostava unutar Zagreba" ? "Zagreb" : formData.grad}
                                readOnly={selectedOption==="Dostava unutar Zagreba"} // Set readOnly based on selectedOption
                                onChange={handleChange}
                                className="font-pro"
                                placeholder='Grad*'
                                type='text'
                            />
                                {selectedOption==="Dostava izvan Zagreba" && <span className="font-pro" style={{color: "red"}}>{cityError}</span>}
                            </MDBRow>

                            <MDBRow style={{paddingBottom: "1.5rem"}}>
                            <MDBInput
                                name="postcode"
                                value={selectedOption==="Dostava unutar Zagreba" ? "10000" : formData.postcode}
                                readOnly={selectedOption==="Dostava unutar Zagreba"} // Set readOnly based on selectedOption
                                onChange={handleChange}
                                className="font-pro"
                                placeholder='Poštanski broj*'
                                type='text'
                            />
                                {selectedOption==="Dostava izvan Zagreba" && <span className="font-pro" style={{color: "red"}}>{postcodeError}</span>}
                            </MDBRow>

                            <MDBRow style={{paddingBottom: "1.5rem"}}>
                            <MDBInput
                                name="country"
                                value="Hrvatska"
                                readonly
                                className="font-pro mb-4"
                                placeholder='Država'
                                type='text'
                            />
                            </MDBRow>

                            </>}
                            <MDBTextArea
                                name="additionalInfo"
                                value={formData.additionalInfo}
                                onChange={handleChange}
                                placeholder='Dodatne informacije (nije obavezno)'
                                rows={4}
                                className="mb-4 font-pro"
                            />
                            {/*<button type="submit" className="btn btn-primary">Submit</button>*/}
                        </form>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    </div>

        return (
            <div style={{}} className="font-tiskani padding-top-navbar">
            <section className="h-100 h-custom padding">
                <MDBContainer className="container-cart-1 py-5 h-100 color-white" style={{justifyContent:"center"}}>
                    <MDBRow className="justify-content-center align-items-center h-100">
                        <MDBCol>
                            <MDBTable responsive>
                                <MDBTableHead>
                                    <tr>
                                        <th scope="col" className="h5 font-pro">
                                            Košarica
                                        </th>
                                        {/*<th scope="col" className="h6 font-tiskani">Format</th>*/}
                                        {showColumns && <th scope="col" style={{textAlign: "center"}} className="h6 font-pro">Količina</th>}
                                        {showColumns && <th scope="col"  style={{textAlign: "center"}} className="h6 font-pro">Cijena</th>}
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {nodes}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCol>
                        {/*<div style={{float: "right", textAlign: "right"}}>*/}
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                        <p className="mb-2 font-pro">Ukupno bez popusta: </p>
                        <p className="mb-2 font-pro">{parseFloat(totalWithoutDiscount).toFixed(2)}€</p>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <p className="mb-2 font-pro">Popust: </p>
                            <p className="mb-2 font-pro">-{parseFloat(totalWithoutDiscount-total).toFixed(2)}€</p>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <p className="mb-2 font-pro">Ukupno: </p>
                            <p className="mb-2 font-pro">{parseFloat(total).toFixed(2)}€</p>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <p className="mb-2 font-pro">Dostava: </p>
                            <p className="mb-2 font-pro">{getPrice(selectedOption).toFixed(2)}€</p>
                        </div>
                        <hr style={{ width: '100%', color: 'lightgray', margin: '10px 0' }} />
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <p className="mb-2 font-pro">Sveukupno: </p>
                            <p className="mb-2 font-pro">{(parseFloat(total)+parseFloat(getPrice(selectedOption))).toFixed(2)}€</p>
                        </div>
                    </MDBRow>
                </MDBContainer>
            </section>
                <div className="outer-div" >
                    <div className="left-aligned-text">
                        <div>
                            <h2 className="font-pro veliki">Dostava</h2>
                        </div>
                        <ul style={{marginBottom: "4rem"}}>
                            {/*{shippingOptions.map((option, index) => (*/}
                            {/*    // <li key={index}>*/}
                            {/*    <div className="option">*/}
                            {/*        <label className="font-pro">*/}
                            {/*            <input*/}
                            {/*                type="radio"*/}
                            {/*                value={option.option}*/}
                            {/*                checked={selectedOption === option.option}*/}
                            {/*                onChange={() => handleOptionChange(option.option)}*/}
                            {/*            />*/}
                            {/*            {option.option} - {option.price !== 0 ? `€${option.price.toFixed(2)}` : 'besplatno'}*/}
                            {/*        </label>*/}
                            {/*    </div>*/}
                            {/*    // </li>*/}
                            {/*))}*/}
                            <div className="option">
                                <label className="font-pro">
                                    <input
                                        type="radio"
                                        value={shippingOptions[0].option}
                                        checked={selectedOption === shippingOptions[0].option}
                                        onChange={() => handleOptionChange(shippingOptions[0].option)}
                                    />
                                    {shippingOptions[0].option} - {shippingOptions[0].price !== 0 ? `€${shippingOptions[0].price.toFixed(2)}` : 'besplatno'}
                                </label>
                            </div>
                            <div className="option">
                                <label className="font-pro">
                                    <input
                                        type="radio"
                                        value={shippingOptions[1].option}
                                        checked={selectedOption === shippingOptions[1].option}
                                        onChange={() => handleOptionChange(shippingOptions[1].option)}
                                    />
                                    {shippingOptions[1].option} - {shippingOptions[1].price !== 0 ? `€${shippingOptions[1].price.toFixed(2)}` : 'besplatno'}
                                </label>
                            </div>
                            <div className="option">
                                <label className="font-pro">
                                    <input
                                        type="radio"
                                        value={shippingOptions[2].option}
                                        checked={selectedOption === shippingOptions[2].option}
                                        onChange={() => handleOptionChange(shippingOptions[2].option)}
                                    />
                                    {shippingOptions[2].option} - {shippingOptions[2].price !== 0 ? `€${shippingOptions[2].price.toFixed(2)}` : 'besplatno'}
                                </label>
                            </div>
                            {formFields}
                            {/*{selectedOption === 'Pickup u salonu (Klaićeva 18)' && formFields}*/}
                            {/*{selectedOption === "Dostava unutar Zagreba" && formFields}*/}
                            {/*{selectedOption === "Dostava izvan Zagreba" && formFields}*/}
                        </ul>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <p className="mb-2 font-pro">Ukupno: </p>
                            <p className="mb-2 font-pro">{total}€</p>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <p className="mb-2 font-pro">Dostava: </p>
                            <p className="mb-2 font-pro">{getPrice(selectedOption).toFixed(2)}€</p>
                        </div>
                        <hr style={{ width: '100%', color: 'lightgray', margin: '10px 0' }} />
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <p className="mb-2 font-pro">Sveukupno: </p>
                            <p className="mb-2 font-pro">{(parseFloat(total)+parseFloat(getPrice(selectedOption))).toFixed(2)}€</p>
                        </div>
                        {/*<div style={{display: "flex", justifyContent: "right"}}>*/}
                        {/*    <button id="button-pay"  type="submit" form="myForm" className="font-pro">Idi na plaćanje</button>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="outer-div" >
                    <div className="left-aligned-text">
                        <div>
                            <h2 className="font-pro veliki">Plaćanje</h2>
                        </div>
                        <ul>
                            <div className="option">
                                <label className="font-pro">
                                    <input
                                        type="radio"
                                        value="Putem IBAN-a"
                                        checked={true}
                                        // onChange={() => handleOptionChange(shippingOptions[0].option)}
                                    />
                                    Uplata putem IBAN-a
                                </label>
                            </div>
                        </ul>
                        <div style={{display: "flex", justifyContent: "right"}}>
                            {/*<Link to={{*/}
                            {/*    pathname: '/payment',*/}
                            {/*    state: { delivery: selectedOption } // Pass the price data in the state object*/}
                            {/*}}>*/}
                            {/*    <Link to="/payment" state={{ delivery: selectedOption, deliveryPrice: getPrice(selectedOption)}}>*/}

                                <button id="button-pay" onClick={handleSubmit2} className="font-pro">
                                    Idi na plaćanje
                                </button>
                            {/*</Link>*/}
                        </div>
                    </div>
                </div>
            </div>

        );
    }


Cart.propTypes = {
    products: PropTypes.array,
    total: PropTypes.string,
    totalWithoutDiscount: PropTypes.string,
    onCheckout: PropTypes.func,
    getAllProducts: PropTypes.func,
    onIncrement: PropTypes.func,
    onDecrement: PropTypes.func,
    onDeleteAll: PropTypes.func
}

export default Cart
