import {combineReducers} from 'redux'
import { RECEIVE_CATEGORIES} from '../constants/ActionTypes'

const categories = (state, action) => {
    switch (action.type) {
        // case ADD_TO_CART:
        //     return {
        //         ...state,
        //         inventory: state.inventory - 1
        //     }
        default:
            return state
    }
}

const byId = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_CATEGORIES:
            // console.log("akc")
            // console.log(action.products.reduce())
            return {
                ...state,
                ...action.categories.content.reduce((obj, category) => {
                    obj[category.categoryId] = category
                    return obj
                }, {})
            }
        default:
            const { categoryId } = action
            if (categoryId) {
                return {
                    ...state,
                    [categoryId]: categories(state[categoryId], action)
                }
            }
            return state
    }
}

const visibleIds = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_CATEGORIES:
            return action.categories.content.map(category => category.categoryId)
        default:
            return state
    }
}

export default combineReducers({
    byId,
    visibleIds
})

export const getCategory = (state, categoryId) => {
    return state.byId[categoryId];
};


export const getVisibleCategories = state =>
    state.visibleIds.map(id => getCategory(state, id))
