import React from 'react';
import './Footer.css';

function Footer() {
    //<div className='website-rights'>LMI-Consult © 2020</div>
    return (
        <div className='footer-container font-tiskani-mali'>
            <div className="margin-padding footer-background-colour">
                <div className="padding-footer footer-background-colour">
                    <small className='website-rights'>Nina Hršak Beauty Boutique d.o.o. za trgovinu i usluge</small>
                </div>
                <div className="padding-footer footer-background-colour">
                    <small className='website-rights'>IBAN:HR3923600001502869847 Zagrebačka banka d.d.</small>
                </div>
                <div className="padding-footer footer-background-colour">
                    <small className='website-rights'>OIB:24700429146</small>
                </div>
            </div>
            <div className="margin-padding footer-background-colour">
                <div className="container-center footer-background-colour">
                    <p className='font-size-contact font-italic' style={{fontSize: "medium"}}>Kontakt</p>
                </div>
                <div className="padding-footer footer-background-colour">
                    <small className='website-rights'>Ulica Vjekoslava Klaića 12, 10000 ZAGREB, Hrvatska</small>
                </div>
                <div className="padding-footer footer-background-colour">
                    <small className='website-rights'>Email za booking: ninahrsakbeautyboutique@gmail.com</small>
                </div>
                <div className="padding-footer footer-background-colour">
                    <small className='website-rights'>Email za shop: ninahrsakbeautyboutiqueshop@gmail.com</small>
                </div>
            </div>
        </div>
    );
}

export default Footer;
