import React, {useEffect, useState} from 'react'
import './HomeProductList.css'
import "../../../fonts/fonts.css"

import Carousel from "react-bootstrap/Carousel";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";




function generateCarousel(children, itemsPerSlide) {

    const carousels = [];

    const customPrevIcon = <FontAwesomeIcon
        icon={faChevronLeft}
        className="arrow-icon left-arrow"
    />

    const customNextIcon = <FontAwesomeIcon
        icon={faChevronRight}
        className="arrow-icon right-arrow"
    />

    for (let i = 0; i < children.length; i += itemsPerSlide) {
        const slideChildren = children.slice(i, i + itemsPerSlide);
        const carouselItem = (
            <Carousel.Item key={i}>
                <div style={{display:"flex", justifyContent:"center"}} className="white-background">
                {slideChildren.map((child, index) => (
                    <span key={index}>{child}</span>
                ))}
                </div>
            </Carousel.Item>
        );
        carousels.push(carouselItem);
    }

    return (<div className="white-background">
            <Carousel fade prevIcon={customPrevIcon} nextIcon={customNextIcon}>
                {carousels}
            </Carousel>
        </div>
    );
}

const ResponsiveCarousel = ({ children }) => {
    const [itemsPerSlide, setItemsPerSlide] = useState(3);

    const handleResize = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth < 768) {
            setItemsPerSlide(1);
        } else if (screenWidth < 992) {
            setItemsPerSlide(2);
        } else {
            setItemsPerSlide(3);
        }
    };

    useEffect(() => {
        // Set initial itemsPerSlide based on screen size
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array to run the effect only once on mount

    return generateCarousel(children, itemsPerSlide);
};

// Example usage:
// <ResponsiveCarousel>

const HomeProductsList = ({ children }) => (
    <div className="background-white padding-carousel">
        <div className="naslov-istaknuti-proizvodi">Istaknuti proizvodi:</div>
    <ResponsiveCarousel children={children}/>
    </div>
)

export default HomeProductsList;