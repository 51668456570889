import PropTypes from "prop-types";
import "./Order.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const Order = ({ order }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <tr className="font-tiskani">
            {windowWidth > 700 && (
                <td className="align-middle font-tiskani-order">{order.orderId}</td>
            )}
            <td className="align-middle font-tiskani-order">{order.orderDate}</td>
            <td className="align-middle font-tiskani-order" style={{color: order.statusColor}}>{order.statusLabel}</td>
            <td className="align-middle font-tiskani-order">
                {order.firstName} {order.lastName}
            </td>
            <td className="align-middle font-tiskani-order">
                {order.address.street} {order.address.city}
            </td>
            <td className="align-middle font-tiskani-order">{order.totalAmount}€</td>
            <td className="align-middle font-tiskani-order">
                <Link to={"./" + order.orderId}>Otvori narudžbu</Link>
            </td>
        </tr>
    );
};

Order.propTypes = {
    order: PropTypes.shape({
        orderId: PropTypes.number.isRequired,
        email: PropTypes.string.isRequired,
        orderStatus: PropTypes.string.isRequired,
        statusLabel: PropTypes.string.isRequired,
        statusColor: PropTypes.string.isRequired,
        totalAmount: PropTypes.number.isRequired,
        orderDate: PropTypes.string.isRequired,
        payment: PropTypes.object,
        totalAmountCart: PropTypes.number.isRequired,
        orderItems: PropTypes.arrayOf(
            PropTypes.shape({
                orderItemId: PropTypes.number.isRequired,
                product: PropTypes.shape({
                    productId: PropTypes.number.isRequired,
                    productName: PropTypes.string.isRequired,
                    brandName: PropTypes.string.isRequired,
                    image: PropTypes.string.isRequired,
                    description: PropTypes.string.isRequired,
                    inventory: PropTypes.number.isRequired,
                    price: PropTypes.number.isRequired,
                    discount: PropTypes.number.isRequired,
                    specialPrice: PropTypes.number.isRequired,
                }).isRequired,
                quantity: PropTypes.number.isRequired,
                discount: PropTypes.number.isRequired,
                orderedProductPrice: PropTypes.number.isRequired,
            })
        ).isRequired,
        address: PropTypes.shape({
            addressId: PropTypes.number.isRequired,
            street: PropTypes.string.isRequired,
            buildingName: PropTypes.string,
            city: PropTypes.string.isRequired,
            state: PropTypes.string,
            country: PropTypes.string.isRequired,
            postcode: PropTypes.string.isRequired,
        }).isRequired,
        shippingMethod: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        phoneNumber: PropTypes.string.isRequired,
        companyName: PropTypes.string,
        shippingMethodPrice: PropTypes.number.isRequired,
    }).isRequired,
};

export default Order;
