import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './ProductPage.css'
import PropTypes from 'prop-types'
import {useNavigate} from "react-router-dom";

const ProductPageAdmin = ({ product, onUpdateProduct }) => {
    const [editedProduct, setEditedProduct] = useState(product);
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        // If the input name is 'discount', validate the value
            // For other input fields, update the state directly
            setEditedProduct({ ...editedProduct, [name]: value });
    };

    const handleUpdateProduct = () => {
        onUpdateProduct(editedProduct);
        navigate("/shop")
    };

    return (
        <div style={{ paddingTop: "10rem" }}>
            <div className="display-flex-div">
                <div className="flex-grow-div">
                    <div className="big-container">
                        <div className="container">
                            <div className="column font-tiskani-mali">
                                <div className="form-group">
                                    <label htmlFor="productName">Ime:</label>
                                    <input type="text" className="form-control" id="productName" name="productName"
                                           value={editedProduct.productName} onChange={handleInputChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="brandName">Ime brenda:</label>
                                    <input type="text" className="form-control" id="brandName" name="brandName"
                                           value={editedProduct.brandName} onChange={handleInputChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="shortDescription">Kratki opis:</label>
                                    <input type="text" className="form-control" id="shortDescription" name="shortDescription"
                                           value={editedProduct.shortDescription} onChange={handleInputChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="description">Opis:</label>
                                    <textarea className="form-control description-input" style={{minHeight: "20rem"}}
                                              id="description" name="description"
                                              value={editedProduct.description} onChange={handleInputChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="price">Cijena:</label>
                                    <input type="number" className="form-control" id="price" name="price"
                                           value={editedProduct.price} onChange={handleInputChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="discount">Popust: (0-100)%</label>
                                    <input type="text" className="form-control" id="discount" name="discount"
                                           value={editedProduct.discount} onChange={handleInputChange}/>
                                </div>
                                {/*<div className="form-group">*/}
                                {/*    <label htmlFor="specialPrice">Special Price:</label>*/}
                                {/*    <input type="text" className="form-control" id="specialPrice" name="specialPrice"*/}
                                {/*           value={editedProduct.specialPrice} onChange={handleInputChange}/>*/}
                                {/*</div>*/}
                                <div className="form-group">
                                    <label htmlFor="inventory">Na zalihi:</label>
                                    <input type="number" className="form-control" id="inventory" name="inventory"
                                           value={editedProduct.inventory} onChange={handleInputChange}/>
                                </div>
                                <div className="button-add">
                                    <button type="button" id="btn-add-to-cart" className="font-tiskani-mali btn btn-primary margin-top" onClick={handleUpdateProduct}>Save Changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ProductPageAdmin.propTypes = {
    product: PropTypes.shape({
        productName: PropTypes.string.isRequired,
        brandName: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        inventory: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired, // Ensure image is required
        specialPrice: PropTypes.number.isRequired,
        discount: PropTypes.number.isRequired,
    }).isRequired,
    onUpdateProduct: PropTypes.func.isRequired
}

export default ProductPageAdmin;
