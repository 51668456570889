import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { addToCartQuantity, updateProduct } from '../actions'
import { getProduct } from '../reducers/products'
import ProductPage from '../pages/shop/product/ProductPage'
import ProductPageAdmin from '../pages/shop/product/ProductPageAdmin'
import { useParams } from 'react-router-dom'

const withRouter = (Component) => (props) => {
    const params = useParams()
    return <Component {...props} params={params} />
}

const SingleProductContainer = ({product,
                                    addToCartQuantity,
                                    updateProduct,
                                }) => {

    console.log(product)
    // const productId = useParams()?.productId;
    const isAuthenticated = localStorage.getItem("auth") === "true"
    const reduxState = useSelector((state) => state); // Get the entire Redux state

    useEffect(() => {
        // console.log('Redux State:', reduxState); // Log the entire Redux state
    }, [reduxState]);

    if (!product) {
        return <div>Product not found</div>; // Render error message if product is not found
    }




    return isAuthenticated ? (
        <ProductPageAdmin
            key={product.productId}
            product={product}
            onUpdateProduct={(editedProduct) =>
                updateProduct(editedProduct)
            }
        />
    ) : (
        <ProductPage
            key={product.productId}
            product={product}
            onAddToCartClicked={(quantity) =>
                addToCartQuantity(product.productId, quantity)
            }
        />
    )
}

SingleProductContainer.propTypes = {
    product: PropTypes.shape({
        productId: PropTypes.number.isRequired,
        productName: PropTypes.string.isRequired,
        brandName: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        inventory: PropTypes.number.isRequired,
        specialPrice: PropTypes.number.isRequired,
        discount: PropTypes.number.isRequired,
    }).isRequired,
    addToCartQuantity: PropTypes.func.isRequired,
    updateProduct: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
    product: getProduct(
        state.products,
        parseInt(ownProps.params.productId, 10)
    ),
})

export default withRouter(
    connect(mapStateToProps, { addToCartQuantity, updateProduct })(
        SingleProductContainer
    )
)
