import React, { useState } from 'react';
import './Login.css';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {login as loginAction, getAllOrders} from "../../actions"; // Import getAllOrders action
import { useNavigate } from "react-router-dom";

const Login = ({ getAllOrders, login }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    let navigate = useNavigate();

    const handleUsernameChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log('Submitted email:', email);
        // console.log('Submitted password:', password);

        const formData = {
            email: email,
            password: password
        };

        try {
            console.log("Bfore login")
            const jwt = await login(formData); // Call login action
            // const jwt_storage = localStorage.getItem("jwtToken")
            console.log("JWT token:", jwt);
            // console.log("JWT token:", jwt_storage);
            // localStorage.setItem('jwtToken', jwt); // Store the token in local storage
            getAllOrders(); // Dispatch getAllOrders action after successful login
            navigate('/', { replace: true }); // Navigate to the home page after login
            // Add redirect logic or other actions upon successful login
        } catch (error) {
            console.error("Login failed:", error);
            // Handle login failure, display error message, etc.
        }
    };

    return (
        <div style={{ paddingTop: "10rem" }}>
            <div className="login-container">
                <h2>Login</h2>
                <form onSubmit={handleSubmit} className="login-form">
                    <div>
                        <label htmlFor="username">Email:</label>
                        <input
                            type="text"
                            id="email"
                            value={email}
                            onChange={handleUsernameChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="password">Password:</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                    </div>
                    <button type="submit">Login</button>
                </form>
            </div>
        </div>
    );
};

Login.propTypes = {
    login: PropTypes.func.isRequired,
    getAllOrders: PropTypes.func.isRequired // Add getAllOrders to propTypes
};

const mapDispatchToProps = {
    login: loginAction,
    getAllOrders // Add getAllOrders to mapDispatchToProps
};

export default connect(null, mapDispatchToProps)(Login);
