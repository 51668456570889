import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAllProducts } from '../actions'; // Import getAllProducts action creator
import { addToCart, checkout, removeFromCart, removeAllFromCart } from '../actions';
import {getTotal, getCartProducts, getTotalFullPrice} from '../reducers';
import Cart from '../pages/shop/cart/Cart';

const CartContainer = ({ products, total, totalWithoutDiscount, getAllProducts, checkout, addToCart, removeFromCart, removeAllFromCart }) => {
    useEffect(() => {
        // Dispatch the getAllProducts action when the component mounts
        getAllProducts();
    }, [getAllProducts]);

    if (!products || !total) {
        return <div>Loading...</div>; // Render loading indicator if products or total are not available
    }

    return (
        <Cart
            products={products}
            total={total}
            totalWithoutDiscount={totalWithoutDiscount}
            getAllProducts={getAllProducts}
            onCheckout={(form) => checkout(form)}
            onIncrement={(productId) => addToCart(productId)}
            onDecrement={(productId) => removeFromCart(productId)}
            onDeleteAll={(productId) => removeAllFromCart(productId)}
        />
    );
};

CartContainer.propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape({
        productId: PropTypes.number.isRequired,
        productName: PropTypes.string.isRequired,
        brandName: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        specialPrice: PropTypes.number.isRequired,
        discount: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired
    })),
    total: PropTypes.string,
    totalWithoutDiscount: PropTypes.string,
    getAllProducts: PropTypes.func.isRequired, // Add getAllProducts to propTypes
    checkout: PropTypes.func.isRequired,
    addToCart: PropTypes.func.isRequired,
    removeFromCart: PropTypes.func.isRequired,
    removeAllFromCart: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    products: getCartProducts(state),
    total: getTotal(state),
    totalWithoutDiscount: getTotalFullPrice(state)

});

const mapDispatchToProps = {
    getAllProducts, // Add getAllProducts to mapDispatchToProps
    checkout,
    addToCart,
    removeFromCart,
    removeAllFromCart,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CartContainer);
