import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './ProductPage.css';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import "../../../fonts/fonts.css"
import {useSelector} from "react-redux";

const ProductPage = ({ product, onAddToCartClicked }) => {
    const [quantity, setQuantity] = useState(1);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showSmallImages, setShowSmallImages] = useState(false);
    const [showMediumImages, setShowMediumImages] = useState(false);
    // const [imageHeight, setImageHeight] = useState(0);
    const [isHoveredQuantity, setIsHoveredQuantity] = useState(false);
    const [isHoveredAdd, setIsHoveredAdd] = useState(false);

    const cartProducts = useSelector(state => state.cart.quantityById);
    const stateProducts = useSelector(state => state.products.byId);

    const checkAvailable = (productId) => {
        // console.log("inv", stateProducts[productId].inventory)
        // console.log("cart", cartProducts[productId])
        return (!cartProducts[productId] && stateProducts[productId].inventory > 0) || stateProducts[productId].inventory > cartProducts[productId]
    }




    useEffect(() => {
        const handleResize = () => {
            setShowSmallImages(window.innerWidth < 1100);
            setShowMediumImages(window.innerWidth > 1100);

        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Call the handleResize function once to set the initial state
        handleResize();

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // const isAuthenticated = localStorage.getItem("auth") === "true";

    const urlMapping = {
        "{LINK_FORM1}": "/shop/1",
        "{LINK_FIX2}": "/shop/2",
        "{LINK_FILLER3}": "/shop/3",
        "{LINK_DIRECTIONS}": "/shop/lash-filler-directions",
        "{LINK_SHAMPOO}": "/shop/42",
        "{LINK_PADS}": "/shop/23",
        "{LINK_FIX_GEL}": "/shop/13",
        "{LINK_FILMER}": "/shop/38",
        "{LINK_HELPER}": "/shop/27",
        "{LINK_BRUSH}": "/shop/36",
        "{LINK_COLOR}": "/shop/16",
        "{LINK_BROW_LIFT_1}": "/shop/7",
        "{LINK_BROW_LOCK_2}": "/shop/8",
        "{LINK_BROW_BOMBER_3}": "/shop/9",
        "{LINK_MISTER_FIX}": "/shop/40",
        "{LINK_TOTAL}": "/shop/22",
        "{LINK_SHINY_BLACK}": "/shop/16",
        "{LINK_DEVELOPER}": "/shop/15",
        "{LINK_FIXING_GEL}": "/shop/13",
        "{LINK_LAMICA_GEL}": "/shop/14",
        "{LINK_PICASSO}": "/shop/37",
        "{LINK_SOLO}": "/shop/32",
        "{LINK_FILLERING}": "/shop/38",
    }



    const getFormattedText = (text) => {
        if (text) {
            // Replace placeholders with actual URLs
            let formattedText = text;
            Object.keys(urlMapping).forEach((placeholder) => {
                const url = urlMapping[placeholder];
                const linkRegex = new RegExp(placeholder, 'g');
                formattedText = formattedText.replace(linkRegex, url);
            });

            // Replace newlines with <br> tags
            formattedText = formattedText.replace(/\n/g, '<br>');

            return <div className="opis" dangerouslySetInnerHTML={{ __html: formattedText }} />;
        } else {
            return "";
        }
    };
    // const formattedDescription = product.description.replace(/\n/g, '<br>');

    // const handleQuantityChange = (event) => {
    //     const newQuantity = parseInt(event.target.value, 10);
    //     setQuantity(isNaN(newQuantity) ? 0 : newQuantity);
    // };

    const handleIncrement = () => {
        setQuantity(quantity + 1);
    };

    const handleDecrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const handleAddToCartClick = () => {
        onAddToCartClicked(quantity);
    };

    function getImagePaths(image) {
        if (!image) return [];
        return image.split(',');
    }

    const images = getImagePaths(product.image);

    const handlePrevImage = () => {
        setCurrentImageIndex(prevIndex => prevIndex === 0 ? images.length - 1 : prevIndex - 1);
    };

    const handleNextImage = () => {
        setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    };

    useEffect(() => {
        const resizeHandler = () => {
            if (images && images[currentImageIndex]) {
                // setImageHeight(document.querySelector('.main-image').clientHeight);
            }
        };

        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, [images, currentImageIndex]);

    const divStyle = {
        borderBottom: isHoveredQuantity ? "1px dashed black" : "1px solid",
        borderRight: isHoveredQuantity ? "1px dashed black" : "1px solid",
        borderLeft: isHoveredQuantity ? "1px dashed black" : "none",
        borderTop: isHoveredQuantity ? "1px dashed black" : "none",
        transition: "border-color 0.3s ease",
        padding: "3px",
        margin: "5px"
    };

    const divStyleAdd = {
        borderBottom: isHoveredAdd ? "1px dashed black" : "1px solid",
        borderRight: isHoveredAdd ? "1px dashed black" : "1px solid",
        borderLeft: isHoveredAdd ? "1px dashed black" : "none",
        borderTop: isHoveredAdd ? "1px dashed black" : "none",
        transition: "border-color 0.3s ease",
        padding: "3px",
        margin: "5px",
        animation: "moveDottedBorder 2s linear infinite",
    };

    const productInformation_ = [
        {
            id: 1,
            name: "Opis proizvoda",
            text: getFormattedText(product.description),
            isOpen: false,
        },
        {
            id: 2,
            name: "Upute za korištenje",
            text: getFormattedText(product.usageDirections),
            isOpen: false,
        },
        {
            id: 3,
            name: "Sastojci",
            text: getFormattedText(product.ingredients),
            isOpen: false,
        },
        {
            id: 4,
            name: "Upozorenja",
            text: getFormattedText(product.warning),
            isOpen: false,
        }
        ]

    const [productInfo, setProductInfo] = useState(productInformation_);
    const toggleDivProductInfo = (id) => {
        const updatedInfo = productInfo.map((information) =>
            information.id === id ? { ...information, isOpen: !information.isOpen } : information
        );
        setProductInfo(updatedInfo);
    };

    const content = (info, toggle) => (
        info.text &&
        <div key={info.id}>
            <hr />
            <div className="font-pro" style={{fontSize: "medium"}}>
                <button className="plus-btn" onClick={() => toggle(info.id)}>
                    {info.isOpen ? "-" : "+"}
                </button>
                {info.name}
            </div>
            {info.isOpen && (
                <div className={` slide-down font-pro tretmani-content ${info.isOpen ? 'open' : ''}`} style={{paddingLeft: "1rem", fontSize: "medium"}}>
                    <div className="empty-row"></div>
                    <div>{info.text}</div>
                    <div className="empty-row"></div>
                    {/*<Link style={{color: "black",     textDecorationThickness: "1px"}} to={info.link}>Saznaj više</Link>*/}
                </div>

            )}
            <div className="empty-row"></div>
        </div>
    );


    return (
        <div className="background-white padding-top-navbar">
            <div className="display-flex-div background-white">
                {/*{showSmallImages && (*/}
                {/*    <div className="small-images-column">*/}
                {/*        {images.map((image, index) => (*/}
                {/*            <img*/}
                {/*                key={index}*/}
                {/*                className="small-image"*/}
                {/*                src={"/images/products/"+image}*/}
                {/*                alt={`Small Image ${index + 1}`}*/}
                {/*                onClick={() => setCurrentImageIndex(index)}*/}
                {/*            />*/}
                {/*        ))}*/}
                {/*    </div>)*/}
                {/*}*/}
                <div className="flex-grow-div background-white">
                    <div className="big-container background-white">
                        <div className="container-product-page background-white flex-grow-div">
                            <div className="column  flex-grow-div">
                                <div className="relative-container">
                                <FontAwesomeIcon
                                    icon={faChevronLeft}
                                    className="arrow-icon left-arrow"
                                    onClick={handlePrevImage}
                                />
                                <img
                                    className="main-image"
                                    src={"/images/products/" + images[currentImageIndex]}
                                    alt={`Main ${currentImageIndex + 1}`}
                                    onLoad={(event) => {
                                        // Set the height of the relative container to match the image height
                                        const imageHeight = event.target.height;
                                        event.target.closest('.relative-container').style.height = `${imageHeight}px`;
                                    }}
                                />
                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    className="arrow-icon right-arrow"
                                    onClick={handleNextImage}
                                />
                                </div>
                                <div style={{marginBottom: "1rem"}}>
                                    {showMediumImages &&
                                        <div className="grid-container">
                                    {images.map((image, index) => {
                                        if (index !== currentImageIndex) {
                                            return (
                                                <img
                                                    key={index}
                                                    className="small-image"
                                                    src={"/images/products/" + image}
                                                    alt={`Small ${index + 1}`}
                                                    onClick={() => setCurrentImageIndex(index)}
                                                />
                                            );
                                        } else {
                                            return null; // Skip rendering the main image again
                                        }
                                    })}
                                </div>}
                                </div>
                            </div>
                            <div className="column">
                                <div>
                                    {showSmallImages &&
                                        <div className="grid-container-small">
                                            {images.map((image, index) => {
                                                if (index !== currentImageIndex) {
                                                    return (
                                                        <img
                                                            key={index}
                                                            className="small-image2"
                                                            src={"/images/products/" + image}
                                                            alt={`Small ${index + 1}`}
                                                            onClick={() => setCurrentImageIndex(index)}
                                                        />
                                                    );
                                                } else {
                                                    return null; // Skip rendering the main image again
                                                }
                                            })}
                                        </div>}
                                </div>
                                <div className="brand font-pro">{product.brandName}</div>
                                <div className="naslov font-pro">{product.productName}</div>
                                <div className="kratki-opis font-pro">{product.shortDescription}</div>
                                {product.discount !== 0 &&
                                    <div className="sale-bg font-pro">Uštedi {product.discount} %</div>
                                }
                                <div className="cijena">
                                    <span className="font-pro">Cijena: </span>
                                    {product.discount !== 0 &&
                                        <button
                                            className="margin-bottom-0 text-btn font-tiskani"
                                            style={{marginRight: "1rem"}}
                                        >{product.specialPrice + "€"}</button>
                                    }
                                    {product.discount !== 0 &&
                                        <button
                                            className="text-btn strike-through-text dark-grey-text font-pro"
                                            // style={{margin: "0.4rem"}}
                                        >{product.price + "€"}
                                        </button>
                                    }
                                    {product.discount === 0 &&
                                        <button
                                            className="font-pro margin-bottom-0 text-btn font-pro">{product.price + "€"}</button>
                                    }
                                    {/*{"Cijena: "+product.price + "€"}*/}
                                </div>
                                <div className="quantity_label font-pro">
                                    <label htmlFor="quantity">Količina:</label>
                                </div>
                                <div className="d-flex flex-row align-items-left font-pro" style={{ alignItems: "flex-start" }}>
                                    <div style={divStyle}
                                         onMouseEnter={()=>setIsHoveredQuantity(true)}
                                         onMouseLeave={()=>setIsHoveredQuantity(false)}>
                                        <button className="px-2 btn-cart" color="black" style={{ marginBottom: '0' }} onClick={handleDecrement}>
                                            <span style={{ fontWeight: "bold" }} className="">-</span>
                                        </button>
                                        <span style={{ margin: '0 5px' }}>{quantity}</span>
                                        <button className="px-2 btn-cart" color="grey" style={{ marginBottom: '0' }} onClick={handleIncrement}>
                                            <span style={{ fontSize: 'large' }} className="">+</span>
                                        </button>
                                    </div>
                                </div>
                                {/*<div className="quantity">*/}
                                {/*    <input*/}
                                {/*        type="number"*/}
                                {/*        id="quantity"*/}
                                {/*        name="quantity"*/}
                                {/*        min="0"*/}
                                {/*        max="100"*/}
                                {/*        value={quantity}*/}
                                {/*        onChange={handleQuantityChange}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <div className="button-add">
                                    <button
                                        id="btn-add-to-cart"
                                        type="button"
                                        className={`font-pro btn btn-primary margin-top ${checkAvailable(product.productId) ? "" : "sold-out"}`}
                                        disabled={!checkAvailable(product.productId)}
                                        onClick={handleAddToCartClick}
                                        onMouseEnter={() => setIsHoveredAdd(true)}
                                        onMouseLeave={() => setIsHoveredAdd(false)}
                                        style={checkAvailable(product.productId) ? divStyleAdd : {}}
                                    >
                                        {checkAvailable(product.productId) ? "Dodaj u košaricu" : "Nema na zalihi"}
                                    </button>
                                </div>

                                {/*<div className="short-description">Opis proizvoda:</div>*/}
                                {productInfo.map((info) => content(info, toggleDivProductInfo))}
                                {/*<div className="opis" dangerouslySetInnerHTML={{ __html: formattedDescription }} />*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


ProductPage.propTypes = {
    product: PropTypes.shape({
        productName: PropTypes.string.isRequired,
        brandName: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        shortDescription: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(PropTypes.string).isRequired,
        usageDirections: PropTypes.string.isRequired,
        ingredients: PropTypes.string.isRequired,
        warning: PropTypes.arrayOf(PropTypes.string).isRequired,
        specialPrice: PropTypes.number.isRequired,
        discount: PropTypes.number.isRequired,
    }).isRequired,
    onAddToCartClicked: PropTypes.func.isRequired
};

export default ProductPage;
