import React, { useState } from "react";
import {Link} from "react-router-dom";
import "./Tretmani.css";
import "../../fonts/fonts.css"

function Tretmani() {

    // const navigate = useNavigate();



    const facialProcedures_ = [

        {
            id: 1,
            name: "Microneedling",
            description: "Description for Microneedling procedure.",
            isOpen: false,
            link: "/tretmani/microneedling"
        },
        {
            id: 2,
            name: "Chemical Peel",
            description: "Description for Chemical Peel procedure.",
            isOpen: false,
            link: "/tretmani/chemical-peel"
        },
        {
            id: 3,
            name: "Trajna šminka",
            description: "Description for Microneedling procedure.",
            isOpen: false,
            link: "/tretmani/microneedling"
        },
        {
            id: 4,
            name: "Brow i lash lift",
            description: "Description for Chemical Peel procedure.",
            isOpen: false,
            link: "/tretmani/chemical-peel"
        },
        // Add more procedures as needed
    ];

    const bodyProcedures_ = [
        {
            id: 1,
            name: "Masaža",
            description: "Description for Chemical Peel procedure.",
            isOpen: false,
            link: "/tretmani/chemical-peel"
        },
        {
            id: 2,
            name: "Madero terapija",
            description: "Description for Microneedling procedure.",
            isOpen: false,
            link: "/tretmani/microneedling"
        },
        {
            id: 3,
            name: "Vacuslim 48",
            description: "Description for Chemical Peel procedure.",
            isOpen: false,
            link: "/tretmani/chemical-peel"
        },
        {
            id: 4,
            name: "Ice sculpting",
            description: "Description for Chemical Peel procedure.",
            isOpen: false,
            link: "/tretmani/chemical-peel"
        },
        // Add more procedures as needed
    ];

    const toggleFacialDiv = (id) => {
        const updatedProcedures = facialProcedures.map((procedure) =>
            procedure.id === id ? { ...procedure, isOpen: !procedure.isOpen } : procedure
        );
        setFacialProcedures(updatedProcedures);
    };

    const [facialProcedures, setFacialProcedures] = useState(facialProcedures_);
    const [bodyProcedures, setBodyProcedures] = useState(bodyProcedures_);

    const toggleDivBody = (id) => {
        const updatedProcedures = bodyProcedures.map((procedure) =>
            procedure.id === id ? { ...procedure, isOpen: !procedure.isOpen } : procedure
        );
        setBodyProcedures(updatedProcedures);
    };



    const content = (procedure, toggle) => (
        <div key={procedure.id}>
            <hr />
            <div className="font-pro" style={{fontSize: "medium"}}>
                <button className="plus-btn" onClick={() => toggle(procedure.id)}>
                    {procedure.isOpen ? "-" : "+"}
                </button>
                {procedure.name}
            </div>
            {procedure.isOpen && (
                <div className={` slide-down font-pro tretmani-content ${procedure.isOpen ? 'open' : ''}`} style={{paddingLeft: "1rem", fontSize: "medium"}}>
                    <div className="empty-row"></div>
                    <div className="font-pro-light">{procedure.description}</div>
                    <div className="empty-row"></div>
                    <Link style={{color: "black",     textDecorationThickness: "1px"}} to={procedure.link}>Saznaj više</Link>
                </div>

            )}
            <div className="empty-row"></div>
        </div>
    );


    return (
           <div className="font-pro" style={{paddingTop: "10rem"}}>
        <div className="img-text-container">
            {/* Div with Image */}
            <div className="img-tretmani-lice" style={{ flex: "1"}}>
                <img
                    src="/images/lice%20maska.jpg" // Replace with the actual image URL
                    alt="Sample"
                    style={{ width: "100%", height: "auto" }}
                />
            </div>

            {/* Div with Text (Open/Close Button) */}
            <div style={{ flex: "1" }}>
                <div
                    className="tretmani-za-lice-container"
                >
                    <div className="font-pro" style={{ fontSize: "x-large" }}>
                        Tretmani za lice
                    </div>
                    {facialProcedures.map((procedure) => content(procedure, toggleFacialDiv))}
                </div>
            </div>
        </div>


            <div className="img-text-container">
                {/* Div with Image */}
                {/* Div with Text (Open/Close Button) */}
                <div style={{ flex: "1" }}>
                    <div
                        className="tretmani-za-tijelo-container"
                    >
                        <div className="font-pro" style={{ fontSize: "x-large" }}>
                            Tretmani za tijelo
                        </div>
                        {bodyProcedures.map((procedure) => content(procedure, toggleDivBody))}
                    </div>
                </div>

                <div className="img-tretmani-tijelo">
                    <img
                        src="/images/tijelo2.jpeg" // Replace with the actual image URL
                        alt="Sample"
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            </div>
        </div>
    );
}

export default Tretmani;
