
// import axios from 'axios';
//mock API
// let API_URL = 'http://localhost:8080';

let API_URL = 'https://mysterious-gorge-57491-71093ed923c9.herokuapp.com'; // Your API base URL


// export default function callApi(endpoint, method = 'GET', body) {
//   return axios({
//     method,
//     url: `${API_URL}/${endpoint}`,
//     data: body
//   }).catch(err => {
//     console.log(err);
//   });
// }

function callApi(endpoint, method = 'GET', body, params, customHeaders) {
    const urlWithParams = new URL(`${API_URL}/${endpoint}`);
    if (params) {
        urlWithParams.search = new URLSearchParams(params).toString();
    }

    const defaultHeaders = {
        'Content-Type': 'application/json' // Specify the content type as JSON
    };

    const headers = {
        ...defaultHeaders,
        ...customHeaders // Merge custom headers with default headers
    };

    const requestOptions = {
        method: method,
        headers: headers,
        body: body ? JSON.stringify(body) : undefined // Convert the body object to a JSON string if provided
    };

    return fetch(urlWithParams, requestOptions)
        .then(response => {
            if (!response.ok) {
                // If response is not OK, check if it's a validation error
                if (response.status === 400) {
                    return response.json().then(error => {
                        // If response is a validation error, throw the error object
                        throw error;
                    });
                } else {
                    // If it's not a validation error, throw a generic error
                    throw new Error('Network response was not ok');
                }
            }
            return response.json(); // Parse the response as JSON
        })
        .catch(error => {
            // Handle any errors that occurred during the fetch
            console.error('Fetch error:', error);
            throw new Error(error); // Rethrow the error for the caller to handle
        });
}


// const getProducts = (cb, params) => {
//     const endpoint = "api/public/products"
//     callApi(endpoint, 'GET',  null, params)
//         .then(data => cb(data))
//         .catch(error => {
//             // Handle or log the error specific to getProducts if needed
//             console.error('Error in getProducts:', error);
//         });
// };

// const getProducts = (callback) => {
//     fetch(`${API_URL}/api/public/products`)
//         .then((response) => response.json())
//         .then((data) => callback(data))
//         .catch((error) => console.error('Error fetching products:', error));
// };

const getProducts = (callback, pageSize = 50) => {
    const params = { pageSize: pageSize };
    fetch(`${API_URL}/api/public/products?${new URLSearchParams(params)}`)
        .then((response) => response.json())
        .then((data) => callback(data))
        .catch((error) => console.error('Error fetching products:', error));
};

const getCategories = (callback, pageSize = 50) => {
    const params = { pageSize: pageSize };
    fetch(`${API_URL}/api/public/categories?${new URLSearchParams(params)}`)
        .then((response) => response.json())
        .then((data) => {
            console.log("data", data)
            callback(data)
        })
        .catch((error) => console.error('Error fetching categories:', error));
};

const getProductsPromise = (pageSize = 20) => {
    const params = { pageSize: pageSize };
    return fetch(`${API_URL}/api/public/products?${new URLSearchParams(params)}`)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Failed to fetch products');
            }
            return response.json();
        })
        .catch((error) => {
            console.error('Error fetching products:', error);
            throw error; // Rethrow the error to be caught by the caller
        });
};

const buyProducts = (formData, cb, params) => {
    const endpoint = "api/public/users/order";
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    };
    const urlWithParams = new URL(`${API_URL}/${endpoint}`);
    fetch(urlWithParams, requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 400) {
                    return response.json().then(error => {
                        // Extract reason from the error JSON and alert the user
                        alert(JSON.stringify(error));
                        throw error; // Rethrow the error for the caller to handle
                    });
                } else {
                    throw new Error('Network response was not ok');
                }
            }
            return response.json(); // Parse the response as JSON
        })
        .then(data => cb(data))
        .catch(error => {
            // Handle or log the error specific to buyProducts if needed
            console.error('Error in buyProducts:', error);
        });
};




const login = (formData, cb, params) => {
    const endpoint = "api/login"
    console.log("in calling api")
    callApi(endpoint, 'POST',  formData, params)
        .then(data => cb(data))
        .catch(error => {
            // Handle or log the error specific to getProducts if needed
            console.error('Error in getProducts:', error);
        });
};

const confirmOrder = (orderId, cb) => {
    const params = {
        orderId: orderId
    };
    const endpoint = "api/public/users/order/confirm"
    callApi(endpoint, 'POST',  null, params)
        .then(data => cb(data))
        .catch(error => {
            // Handle or log the error specific to confirmOrder if needed
            console.error('Error in confirm order:', error);
            // Assuming 'error' is the plain text response from the API
            // You can handle the error or pass it to the callback
            cb(error);
        });
};


const cancelOrderUser = (orderId, cb) => {
    const params = {
        orderId: orderId
    };
    const endpoint = "api/public/users/order/cancel"
    callApi(endpoint, 'POST',  null, params)
        .then(data => cb(data))
        .catch(error => {
            // Handle or log the error specific to confirmOrder if needed
            console.error('Error in confirm order:', error);
            // Assuming 'error' is the plain text response from the API
            // You can handle the error or pass it to the callback
            cb(error);
        });
};


// const getOrders = (callback, pageSize = 20) => {
//     const params = { pageSize: pageSize };
//     fetch(`${API_URL}/api/admin/orders?${new URLSearchParams(params)}`)
//         .then((response) => response.json())
//         .then((data) => callback(data))
//         .catch((error) => console.error('Error fetching products:', error));
// };

const getOrders = (callback, pageSize = 20) => {
    const params = { pageSize: pageSize };
    const token = localStorage.getItem('jwtToken');
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);

    console.log("headers", token)

    fetch(`${API_URL}/api/admin/orders?${new URLSearchParams(params)}`, {
        method: 'GET',
        headers: headers
    })
        .then((response) => {
            if (!response.ok) {
                console.log(response)
            }
            return response.json();
        })
        .then((data) => callback(data))
        .catch((error) => console.error('Error fetching products:', error));
};



const orderSent = (orderId, cb) => {
    const endpoint = "api/admin/orders/sent";
    const token = localStorage.getItem('jwtToken');
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    // Define params object including orderId
    const params = {
        orderId: orderId
    };
    callApi(endpoint, 'POST', null, params, headers)
        .then(data => cb(data))
        .catch(error => {
            // Handle or log the error specific to getOrders if needed
            console.error('Error in getOrders:', error);
        });
};

const orderPaid = (orderId, cb) => {
    const endpoint = "api/admin/orders/paid";
    const token = localStorage.getItem('jwtToken');
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    // Define params object including orderId
    const params = {
        orderId: orderId
    };
    callApi(endpoint, 'POST', null, params, headers)
        .then(data => cb(data))
        .catch(error => {
            // Handle or log the error specific to getOrders if needed
            console.error('Error in getOrders:', error);
        });
};

const orderNotSent = (orderId, cb) => {
    const endpoint = "api/admin/orders/not-sent";
    const token = localStorage.getItem('jwtToken');
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    // Define params object including orderId
    const params = {
        orderId: orderId
    };
    callApi(endpoint, 'POST', null, params, headers)
        .then(data => cb(data))
        .catch(error => {
            // Handle or log the error specific to getOrders if needed
            console.error('Error in getOrders:', error);
        });
};

const orderNotPaid = (orderId, cb) => {
    const endpoint = "api/admin/orders/not-paid";
    const token = localStorage.getItem('jwtToken');
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    // Define params object including orderId
    const params = {
        orderId: orderId
    };
    callApi(endpoint, 'POST', null, params, headers)
        .then(data => cb(data))
        .catch(error => {
            // Handle or log the error specific to getOrders if needed
            console.error('Error in getOrders:', error);
        });
};

const deleteOrder = (orderId, cb) => {
    console.log("deleting in api")
    const endpoint = "api/admin/orders/delete";
    const token = localStorage.getItem('jwtToken');
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    // Define params object including orderId
    const params = {
        orderId: orderId
    };
    callApi(endpoint, 'DELETE', null, params, headers)
        .then(data => cb(data))
        .catch(error => {
            // Handle or log the error specific to getOrders if needed
            console.error('Error in getOrders:', error);
        });
};

const cancelOrderAdmin = (orderId, cb) => {
    const endpoint = "api/admin/orders/cancel";
    const token = localStorage.getItem('jwtToken');
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    // Define params object including orderId
    const params = {
        orderId: orderId
    };
    callApi(endpoint, 'POST', null, params, headers)
        .then(data => cb(data))
        .catch(error => {
            // Handle or log the error specific to getOrders if needed
            console.error('Error in getOrders:', error);
        });
};


const updateProductImage = (productId, imageFile, cb) => {
    // Construct the FormData object to send the image file
    const formData = new FormData();
    formData.append('image', imageFile);
    const token = localStorage.getItem('jwtToken');
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    // Define the endpoint for the API
    const endpoint = `api/admin/products/${productId}/image`; // Adjust the endpoint as per your API

    // Call the API
    callApi(endpoint, 'PUT', formData, "", headers)
        .then(data => cb(data)) // Pass the response data to the callback
        .catch(error => {
            // Handle or log the error specific to updateProductImage if needed
            console.error('Error in updating product image:', error);
            // Pass the error to the callback
            cb(error);
        });
};

const updateProduct = (productId, productData, cb) => {
    // Define the endpoint for the API
    const endpoint = `api/admin/products/${productId}`; // Adjust the endpoint as per your API

    const token = localStorage.getItem('jwtToken');
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    console.log(productData)
    // Call the API
    callApi(endpoint, 'PUT', productData, "", headers)
        .then(data => cb(data)) // Pass the response data to the callback
        .catch(error => {
            // Handle or log the error specific to updateProduct if needed
            console.error('Error in updating product:', error);
            // Pass the error to the callback
            cb(error);
        });
};


const api = {
    getProducts,
    buyProducts,
    confirmOrder,
    cancelOrderUser,
    cancelOrderAdmin,
    login,
    updateProduct,
    updateProductImage,
    orderSent,
    getOrders,
    orderPaid,
    deleteOrder,
    orderNotSent,
    orderNotPaid,
    getProductsPromise,
    getCategories
};

export default api;


