import { combineReducers } from 'redux';
import { RECEIVE_ORDERS } from '../constants/ActionTypes';

// Define a mapping object for order status codes to labels
const statusLabels = {
    CREATED: "Stvoreno",
    CONFIRMED: "Potvrđeno",
    CANCELED_BY_USER: "Otkazao Korisnik",
    CANCELED_BY_ADMIN: "Otkazao Admin",
    PAID: "Plaćeno",
    SENT: "Poslano"
};

// Define a mapping object for order status codes to colors
const statusColors = {
    CREATED: "grey",
    CONFIRMED: "purple",
    CANCELED_BY_USER: "grey",
    CANCELED_BY_ADMIN: "grey",
    PAID: "blue",
    SENT: "green"
};

// Function to get the status label based on the status code
const getStatusLabel = (statusCode) => {
    return statusLabels[statusCode] || statusCode; // Return the status label from the mapping or the status code itself if not found
};

// Function to get the status color based on the status code
const getStatusColor = (statusCode) => {
    return statusColors[statusCode] || "black"; // Return the status color from the mapping or 'black' if not found
};

// Reducer for individual orders
const orders = (state, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

// Reducer for order by ID
const byId = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_ORDERS:
            return {
                ...state,
                ...action.orders.content.reduce((obj, order) => {
                    // Map order status code to status label and color
                    const statusLabel = getStatusLabel(order.orderStatus);
                    const statusColor = getStatusColor(order.orderStatus);
                    obj[order.orderId] = { ...order, statusLabel, statusColor }; // Include status label and color in the order object
                    return obj;
                }, {})
            };
        default:
            const { orderId } = action;
            if (orderId) {
                return {
                    ...state,
                    [orderId]: orders(state[orderId], action)
                };
            }
            return state;
    }
};

// Reducer for visible order IDs
const visibleIds = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_ORDERS:
            return action.orders.content.map(order => order.orderId);
        default:
            return state;
    }
};

// Combine reducers
export default combineReducers({
    byId,
    visibleIds
});

// Selector to get an order by ID
export const getOrder = (state, orderId) => {
    return state.byId[orderId];
};

// Selector to get visible orders
export const getVisibleOrders = state =>
    state.visibleIds.map(id => getOrder(state, id));
