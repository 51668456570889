import './Kontakt.css'
import salon from "../images/salon.jpg"
import "../fonts/fonts.css"

function Kontakt() {

    return (
        <div style={{paddingTop: "5rem"}}>
        <div className="big-container-contact font-tiskani">
            <div className="side-by-side">
                <div className="centered-container font-tiskani-kontakt padding-15 contact-container">
                    <div className="font-italic padding-down font-large">
                        Kontaktirajte nas
                    </div>
                    <div className=" padding-down">
                        Ulica Vjekoslava Klaića 12, 10000 Zagreb
                    </div>
                    <div className=" padding-down">
                        ninahrsakbeautyboutique@gmail.com
                    </div>
                    <div className=" padding-down">
                        ninahrsakbeautyboutiqueshop@gmail.com
                    </div>
                    <div className=" padding-down">
                        091 5000 636
                    </div>
                </div>
            </div>

            <div className="side-by-side img-container">
                <img src={salon} className="img-max" alt="Jesi"/>

            </div>
            {/*<div className="contact-container">*/}
            {/*    <div className="container bg-light text-dark lmi-container">*/}
            {/*        <h2 className="naslov">Kontakt za prodaju</h2>*/}
            {/*        <p className="fontstyle" style={{marginTop: '20px'}}>LMI Consult, obrt za usluge</p>*/}
            {/*        <p className="fontstyle">Ime i prezime: Ljerka Juroš</p>*/}
            {/*        <p className="fontstyle">Email adresa: lmi-consult@outlook.com</p>*/}
            {/*    </div>*/}

            {/*    <div className="container bg-light text-dark projekt trnsko-container">*/}
            {/*        <h2 className="naslov" style={{marginBottom: '20px'}}>Informacije o investitoru:</h2>*/}
            {/*        <div>PROJEKT SLOBOŠTINA d.o.o.</div>*/}
            {/*        <div >Ulica Pavla Štoosa 27, 10 000 ZAGREB, Hrvatska</div>*/}
            {/*        <div>IBAN:HR3923600001502869847 Zagrebačka banka d.d.</div>*/}
            {/*        <div>OIB:24700429146</div>*/}

            {/*        <div>Upisano u Trgovački sud u Zagrebu</div>*/}
            {/*        <div>MBS:081385052</div>*/}
            {/*        <div>Temeljni kapital 20.000,00 kn uplaćen u cijelosti.</div>*/}
            {/*        <div>Članovi uprave: Krunoslav Juroš dipl.ing.građ.</div>*/}
            {/*        <div>Transakcijski računi: </div>*/}
            {/*        <ul>*/}
            {/*        <li>HR1223600001102944862 Zagrebačka banka d.d.</li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="image-container">*/}
            {/*    <img src="images/Tabla.jpg" style={{maxHeight: '100%', maxWidth: '100%'}}/>*/}
            {/*</div>*/}
        </div>
        </div>


    );

}

export default Kontakt;