import React from 'react'
import PropTypes from 'prop-types'
import "./Product.css"


const ProductOnPayment = ({ brandName, image, price, quantity, productName  }) => {

    const firstImage = image.split(",")[0]

    return(
        <tr>
            <th scope="row">
                <div className="d-flex align-items-center">
                    <img
                        // src={img}
                        src={"/images/products/" + firstImage}
                        fluid
                        className="rounded-3"
                        style={{width: "5rem"}}
                        alt="Book"
                    />
                    <div className="flex-column ms-4">
                        <p className="mb-2 font-tiskani-mali font-weight-normal font-size-cart">{brandName +  " " + productName}</p>
                        {/*<p className="mb-0">Daniel Kahneman</p>*/}
                    </div>
                    {/*<div className="flex-column ms-4">*/}
                    {/*    <p className="mb-2 font-tiskani-mali font-weight-normal">{productName}</p>*/}
                    {/*    /!*<p className="mb-0">Daniel Kahneman</p>*!/*/}
                    {/*</div>*/}
                </div>
            </th>
            <td className="align-middle">
                <div className="d-flex flex-row align-items-center" style={{justifyContent: "center"}}>
                    <div  //style={{border: "3px solid #FFEBF5", borderRadius: "10px", paddingDown: "2px"}}
                         >
                        <span style={{margin: '0 5px'}}>{quantity}</span>
                    </div>
                </div>
                <div>
                </div>
            </td>
            <td className="align-middle">
                <p className="mb-0 font-weight-normal font-tiskani-mali">
                    {(price*quantity).toFixed(2)}€
                </p>
            </td>
        </tr>)
}

ProductOnPayment.propTypes = {
    productId: PropTypes.number,
    image: PropTypes.string,
    price: PropTypes.number,
    inventory: PropTypes.number,
    productName: PropTypes.string,
    brandName: PropTypes.string,
    quantity: PropTypes.number
}

export default ProductOnPayment
