import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { applyMiddleware, createStore } from "redux";
import reducer from "./reducers";
import { initializeCartPersistence } from "./reducers/cart";
import {getAllCategories, getAllProducts} from "./actions";
import ErrorBoundary from "./error/ErrorBoundary";

// Define middleware
const middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger());
}

// Create Redux store
const store = createStore(
    reducer,
    applyMiddleware(...middleware)
);

// Initialize cart persistence
initializeCartPersistence(store);

// Dispatch initial actions
store.dispatch(getAllProducts());
store.dispatch(getAllCategories());


// Render the app
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <>
                <ErrorBoundary>
                <App id="bootstrap-overrides" />
                </ErrorBoundary>
                <style>
                    {`
            @import url('https://fonts.cdnfonts.com/css/futura-lt-paneuropean');
            @import '~@fortawesome/fontawesome-free/css/fontawesome.css';

          `}
                </style>
            </>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
