import React from 'react';
import '../App.css';
import HeroSection from './HeroSection';
import HomeProductsContainer from "../containers/HomeProductsContainer";

function Home() {
  return (
    <>
        <div>
            <HeroSection />
      {/*<InfoSection />*/}
      {/*      <FeaturedProducts/>*/}
            < HomeProductsContainer />
        </div>
    </>
  );
}

export default Home;
