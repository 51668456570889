import React, { useEffect, useState } from 'react';
import './Login.css';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAllOrders } from "../../actions";
import { getVisibleOrders } from "../../reducers/order";
import { MDBCol, MDBContainer, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import Order from "./Order";

const Orders = ({ orders, getAllOrders }) => {
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [filterStatus, setFilterStatus] = useState("");
    const [filterShipping, setFilterShipping] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        getAllOrders(); // Fetch all orders when the component mounts
    }, [getAllOrders]);


    useEffect(() => {
        // Filter orders based on the selected status, shipping, and date range
        const filtered = orders.filter(order => {
            const statusMatch = filterStatus === "" || order.orderStatus.toLowerCase().includes(filterStatus.toLowerCase());
            const shippingMatch = filterShipping === "" || order.shippingMethod.toLowerCase().includes(filterShipping.toLowerCase());
            const dateMatch = (!startDate || new Date(order.orderDate) >= new Date(startDate)) && (!endDate || new Date(order.orderDate) <= new Date(endDate));
            return statusMatch && shippingMatch && dateMatch;
        });

        // Apply search query filtering
        const searched = searchQuery === ""
            ? filtered
            : filtered.filter(order =>
                order.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                order.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                order.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
                order.address.street.toLowerCase().includes(searchQuery.toLowerCase()) ||
                order.address.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
                order.address.postcode.includes(searchQuery)
            );


        // Sort orders by date in descending order
        const sorted = searched.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));

        setFilteredOrders(sorted);
    }, [orders, filterStatus, filterShipping, startDate, endDate, searchQuery]);

    const handleFilterStatusChange = (e) => {
        setFilterStatus(e.target.value);
    };

    const handleFilterShippingChange = (e) => {
        setFilterShipping(e.target.value);
    };

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    return (
        <div className="font-tiskani" style={{ paddingTop: "10rem" }}>
            <section className="h-100 h-custom padding">
                <MDBContainer className="container-cart py-5 h-100 color-white padding" style={{ justifyContent: "center" }}>
                    <MDBRow className="justify-content-center align-items-center h-100">
                        <MDBCol>
                            <div className="filter-div">
                                <label>Status:</label>
                                <select value={filterStatus} onChange={handleFilterStatusChange}>
                                    <option value="">Svi</option>
                                    <option value="CREATED">Stvoreno</option>
                                    <option value="CONFIRMED">Potvrđeno</option>
                                    <option value="CANCELED_BY_USER">Otkazao korisnik</option>
                                    <option value="CANCELED_BY_ADMIN">Otkazao admin</option>
                                    <option value="PAID">Plaćeno</option>
                                    <option value="SENT">Poslano</option>
                                </select>
                            </div>
                            <div className="filter-div">
                                <label>Način dostave:</label>
                                <select value={filterShipping} onChange={handleFilterShippingChange}>
                                    <option value="">All</option>
                                    <option value="Pickup u salonu (Klaićeva 18)">Pickup u salonu (Klaićeva 18)</option>
                                    <option value="Dostava unutar Zagreba">Dostava unutar Zagreba</option>
                                    <option value="Dostava izvan Zagreba">Dostava izvan Zagreba</option>
                                </select>
                            </div>
                            <div className="filter-div">
                                <label>Datum od:</label>
                                <input type="date" value={startDate} onChange={handleStartDateChange} />
                            </div>
                            <div className="filter-div">
                                <label>Datum do:</label>
                                <input type="date" value={endDate} onChange={handleEndDateChange} />
                            </div>
                            <div className="filter-div" style={{paddingBottom: "2rem"}}>
                                <label>Tražilica:</label>
                                <input type="text" value={searchQuery} onChange={handleSearchInputChange} />
                            </div>
                            <MDBTable responsive>
                                <MDBTableHead>
                                    <tr>
                                        {windowWidth > 700 && (
                                            <th scope="col" className="h6 font-tiskani-order">ID narudžbe</th>
                                            )}
                                        <th scope="col" className="h6 font-tiskani-order">Datum narudžbe</th>
                                        <th scope="col" className="h6 font-tiskani-order">Status</th>
                                        <th scope="col" className="h6 font-tiskani-order">Ime i prezime</th>
                                        <th scope="col" className="h6 font-tiskani-order">Adresa</th>
                                        <th scope="col" className="h6 font-tiskani-order">Ukupna cijena</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {filteredOrders.map(order =>
                                        <Order key={order.orderId} order={order} />
                                    )}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </section>
        </div>
    );
};

Orders.propTypes = {
    orders: PropTypes.arrayOf(PropTypes.shape({
        orderId: PropTypes.number.isRequired,
        email: PropTypes.string.isRequired,
        orderStatus: PropTypes.string.isRequired,
        totalAmount: PropTypes.number.isRequired,
        orderDate: PropTypes.string.isRequired,
        payment: PropTypes.object,
        totalAmountCart: PropTypes.number.isRequired,
        orderItems: PropTypes.arrayOf(PropTypes.shape({
            orderItemId: PropTypes.number.isRequired,
            product: PropTypes.shape({
                productId: PropTypes.number.isRequired,
                productName: PropTypes.string.isRequired,
                brandName: PropTypes.string.isRequired,
                image: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                inventory: PropTypes.number.isRequired,
                price: PropTypes.number.isRequired,
                discount: PropTypes.number.isRequired,
                specialPrice: PropTypes.number.isRequired,
            }).isRequired,
            quantity: PropTypes.number.isRequired,
            discount: PropTypes.number.isRequired,
            orderedProductPrice: PropTypes.number.isRequired,
        })).isRequired,
        address: PropTypes.shape({
            addressId: PropTypes.number.isRequired,
            street: PropTypes.string.isRequired,
            buildingName: PropTypes.string,
            city: PropTypes.string.isRequired,
            state: PropTypes.string,
            country: PropTypes.string.isRequired,
            postcode: PropTypes.string.isRequired,
        }).isRequired,
        shippingMethod: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        phoneNumber: PropTypes.string.isRequired,
        companyName: PropTypes.string,
        shippingMethodPrice: PropTypes.number.isRequired,
    })).isRequired,
    getAllOrders: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    orders: getVisibleOrders(state.orders)
});

const mapDispatchToProps = {
    getAllOrders
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Orders);
