import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { addToCart } from '../actions'
import { getVisibleProducts} from '../reducers/products'
import HomeProductsList from "../pages/shop/home/HomeProductList";
import HomeProductItem from "../pages/shop/home/HomeProductItem";

const HomeProductsContainer = ({ products, addToCart }) => (
    <HomeProductsList title="Products">
        {products.map(product =>
            <HomeProductItem
                style={{width: "40rem"}}
                key={product.productId}
                product={product}
                onAddToCartClicked={() => {
                    addToCart(product.productId)
                }} />
        )}
    </HomeProductsList>
)

HomeProductsContainer.propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape({
        productId: PropTypes.number.isRequired,
        productName: PropTypes.string.isRequired,
        brandName: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        inventory: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
    })).isRequired,
    addToCart: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    products: getVisibleProducts(state.products)
})

// const mapStateToProps = state => {
//     debugger
//     const products =  getVisibleProducts(state.products)
//     return {
//
//         products: products
//         // products: state.products
//
//     };
// };

export default connect(
    mapStateToProps,
    { addToCart }
)(HomeProductsContainer)
